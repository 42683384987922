import { useState } from 'react';

import responseOfferUserStatusCloseButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/negotiation_list/response_offer_user_status_close_button_click';
import responseOfferUserStatusElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/user_status/negotiation_list/response_offer_user_status_element_shown';
import { Banner, Button as MagritteButton, Title, Text as MagritteText } from '@hh.ru/magritte-ui';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import HSpacingContainer from 'bloko/blocks/hSpacing/HSpacingContainer';
import { CrossScaleSmallEnclosedFalse, IconColor, IconLink } from 'bloko/blocks/icon';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useMarkAsRead from 'src/components/Applicant/JobSearchStatus/useMarkAsRead';
import useSetJobSearchStatus from 'src/components/Applicant/JobSearchStatus/useSetJobSearchStatus';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import applicantJobSearchStatusSuccess from 'src/components/Notifications/ApplicantJobSearchStatusSuccess';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ApplicantNegotiationStatus } from 'src/models/applicantNegotiations/applicantNegotiationsActionsData';
import { JobSearchStatus, JobSearchStatusForm, JobSearchStatusTrigger } from 'src/models/applicantUserStatuses';

import styles from './job-search-status-banner.less';

const TrlKeys = {
    header: 'applicant.negotiations.jobSearchStatusBanner.header',
    text: 'applicant.negotiations.jobSearchStatusBanner.text',
    buttonAccepted: 'applicant.negotiations.jobSearchStatusBanner.button.accepted',
    buttonThinking: 'applicant.negotiations.jobSearchStatusBanner.button.thinking',
};

const JobSearchStatusBanner: TranslatedComponent = ({ trls }) => {
    const activeTrigger = useSelector((state) => state.applicantUserStatusesTrigger);
    const { addNotification } = useNotification();
    const statusFilter = useSelector((state) => state.router.location.query?.state);
    const [isClosed, setIsClosed] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const setJobSearchStatus = useSetJobSearchStatus(JobSearchStatusForm.ResponsesPageBanner);
    const markAsRead = useMarkAsRead();
    const needToShow = activeTrigger === JobSearchStatusTrigger.NegotiationsScreen;

    const isNegotiationsMagritteExp = useSelector((state) => state.isNegotiationsMagritteExp);

    if (isClosed || !needToShow || statusFilter === ApplicantNegotiationStatus.Discard) {
        return null;
    }

    const onClose = (markRead = false) => {
        if (markRead) {
            markAsRead();
        }
        setIsClosed(true);
    };

    const handleButtonClickAccepted = () => {
        setIsDisabled(true);
        setJobSearchStatus(JobSearchStatus.AcceptedJobOffer)
            .then(() => {
                onClose();
            })
            .finally(() => {
                setIsDisabled(false);
            });
    };
    const handleButtonClickThinking = () => {
        setIsDisabled(true);
        setJobSearchStatus(JobSearchStatus.HasJobOffer)
            .then(() => {
                addNotification(applicantJobSearchStatusSuccess);
                onClose();
            })
            .finally(() => {
                setIsDisabled(false);
            });
    };

    const handleClose = () => {
        responseOfferUserStatusCloseButtonClick();
        onClose(true);
    };

    if (isNegotiationsMagritteExp) {
        return (
            <ElementShownAnchor fn={responseOfferUserStatusElementShown}>
                <Banner
                    stretched
                    onClose={handleClose}
                    title={
                        <Title Element="h5" size="small">
                            {trls[TrlKeys.header]}
                        </Title>
                    }
                    style="positive"
                    content={<MagritteText typography="paragraph-2-regular">{trls[TrlKeys.text]}</MagritteText>}
                    buttonBase={
                        <MagritteButton
                            style="positive"
                            mode="primary"
                            size="small"
                            onClick={handleButtonClickAccepted}
                        >
                            {trls[TrlKeys.buttonAccepted]}
                        </MagritteButton>
                    }
                    buttonAdditional={
                        <MagritteButton
                            style="positive"
                            mode="tertiary"
                            size="small"
                            onClick={handleButtonClickThinking}
                        >
                            {trls[TrlKeys.buttonThinking]}
                        </MagritteButton>
                    }
                />
            </ElementShownAnchor>
        );
    }

    return (
        <ElementShownAnchor fn={responseOfferUserStatusElementShown} className={styles.bannerWrapper}>
            <VSpacing base={5} />
            <HSpacingContainer>
                <HSpacing base={5} />
                <div className={styles.banner}>
                    <div className={styles.bannerTitle}>
                        <Text size={TextSize.Large} strong>
                            {trls[TrlKeys.header]}
                        </Text>
                        <div className={styles.bannerCloseButton}>
                            <IconLink onClick={handleClose}>
                                <CrossScaleSmallEnclosedFalse initial={IconColor.Gray50} />
                            </IconLink>
                        </div>
                    </div>
                    <VSpacing base={2} />
                    <Text>{trls[TrlKeys.text]}</Text>
                    <VSpacing base={4} />
                    <div className={styles.bannerButtons}>
                        <Button
                            onClick={handleButtonClickAccepted}
                            disabled={isDisabled}
                            scale={ButtonScale.Small}
                            kind={ButtonKind.Success}
                        >
                            {trls[TrlKeys.buttonAccepted]}
                        </Button>
                        <Button
                            onClick={handleButtonClickThinking}
                            disabled={isDisabled}
                            scale={ButtonScale.Small}
                            kind={ButtonKind.Success}
                            appearance={ButtonAppearance.Outlined}
                        >
                            {trls[TrlKeys.buttonThinking]}
                        </Button>
                    </div>
                </div>
                <HSpacing base={5} />
            </HSpacingContainer>
            <VSpacing base={5} />
        </ElementShownAnchor>
    );
};

export default translation(JobSearchStatusBanner);
