import { Tag } from '@hh.ru/magritte-ui';
import type { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';

const TrlKeys = {
    responseRate: 'applicant.negotiations.employerStatistics.responseRate',
};

interface EmployerResponseRateTagProps {
    responseRate: number;
}

const EmployerResponseRateTag: TranslatedComponent<EmployerResponseRateTagProps> = ({ trls, responseRate }) => {
    return (
        <Tag>
            {format(trls[TrlKeys.responseRate], {
                '{0}': responseRate,
            })}
        </Tag>
    );
};

export default translation(EmployerResponseRateTag);
