import { useElementShown } from '@hh.ru/analytics-js';
import negotiationItemPendingAutoActionSurveyTagButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/negotiation_list/negotiation_item_pending_auto_action_survey_tag_button_click';
import negotiationItemPendingAutoActionSurveyTagElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/negotiation_list/negotiation_item_pending_auto_action_survey_tag_element_shown';
import { Button, useBreakpoint } from '@hh.ru/magritte-ui';
import { BotOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    tagText: 'applicant.negotiations.pendingAutoActionSurveyTag.text',
};

interface PendingAutoActionSurveyTagProps {
    topicId: number;
    onClick: () => void;
}

const PendingAutoActionSurveyTag: TranslatedComponent<PendingAutoActionSurveyTagProps> = ({
    topicId,
    trls,
    onClick,
}) => {
    const tagRef = useElementShown(negotiationItemPendingAutoActionSurveyTagElementShown, { topicId });

    const { isMobile, isM } = useBreakpoint();

    const handleClick = () => {
        negotiationItemPendingAutoActionSurveyTagButtonClick({ topicId });
        onClick();
    };

    return isMobile ? (
        <Button ref={tagRef} mode="tertiary" style="accent" size="small" stretched onClick={handleClick}>
            {trls[TrlKeys.tagText]}
        </Button>
    ) : (
        <Button
            ref={tagRef}
            mode="tertiary"
            style={isM ? 'neutral' : 'accent'}
            size="small"
            icon={<BotOutlinedSize16 />}
            onClick={handleClick}
            stretched={isM}
        >
            {trls[TrlKeys.tagText]}
        </Button>
    );
};

export default translation(PendingAutoActionSurveyTag);
