import type { TagStyle } from '@hh.ru/magritte-ui';

import { TopicStatus } from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/utils';

export const TagStyleMap: Record<TopicStatus, TagStyle> = {
    [TopicStatus.Accepted]: 'positive',
    [TopicStatus.Rejected]: 'warning',
    [TopicStatus.Viewed]: 'neutral',
    [TopicStatus.NotViewed]: 'neutral',
    [TopicStatus.Archived]: 'neutral',
    [TopicStatus.Deleted]: 'neutral',
} as const;
