import { Alert, Button } from '@hh.ru/magritte-ui';
import { ExclamationTriangleFilledSize24, InfoCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

export enum ConfirmationAlertAction {
    Delete = 'DELETE',
    DeleteSelected = 'DELETE_SELECTED',
    Decline = 'DECLINE',
}

const TrlKeys = {
    [ConfirmationAlertAction.Delete]: {
        title: 'applicant.negotiations.actions.delete.alert.text',
        confirm: 'applicant.negotiations.actions.delete.alert.ok',
        cancel: 'applicant.negotiations.actions.delete.alert.cancel',
    },
    [ConfirmationAlertAction.DeleteSelected]: {
        title: 'applicant.negotiations.actions.deleteSelected.alert.text',
        confirm: 'applicant.negotiations.actions.delete.alert.ok',
        cancel: 'applicant.negotiations.actions.delete.alert.cancel',
    },
    [ConfirmationAlertAction.Decline]: {
        title: 'applicant.negotiations.actions.decline.alert.text',
        confirm: 'applicant.negotiations.actions.decline.alert.ok',
        cancel: 'applicant.negotiations.actions.decline.alert.cancel',
    },
};

export interface ConfirmationAlertProps {
    isVisible: boolean;
    action: ConfirmationAlertAction | null;
    onClose: VoidFunction;
    onConfirm?: VoidFunction | null;
}

const ConfirmationAlert: TranslatedComponent<ConfirmationAlertProps> = ({
    trls,
    isVisible,
    action,
    onConfirm,
    onClose,
}) => {
    const handleConfirmButtonClick = (): void => {
        if (onConfirm) {
            onConfirm();
        }
        onClose();
    };

    if (!action) {
        return null;
    }

    const isDeclineAction = action === ConfirmationAlertAction.Decline;

    return (
        <Alert
            title={trls[TrlKeys[action].title]}
            icon={isDeclineAction ? <InfoCircleFilledSize24 /> : <ExclamationTriangleFilledSize24 />}
            iconStyle={isDeclineAction ? 'neutral' : 'warning'}
            layout="vertical"
            visible={isVisible}
            buttons={
                <>
                    <Button
                        mode="primary"
                        style={isDeclineAction ? 'accent' : 'negative'}
                        size="medium"
                        stretched
                        onClick={handleConfirmButtonClick}
                    >
                        {trls[TrlKeys[action].confirm]}
                    </Button>
                    <Button mode="secondary" style="neutral" size="medium" stretched onClick={onClose}>
                        {trls[TrlKeys[action].cancel]}
                    </Button>
                </>
            }
            onClose={onClose}
        />
    );
};

export default translation(ConfirmationAlert);
