import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';

import VacancySearchItem from 'src/components/VacancySearchItem';
import Source from 'src/components/VacancySearchItem/types/Source';

import ApplicantSuitableVacanciesHeader from 'src/pages/ApplicantNegotiations/components/ApplicantSuitableVacancies/Header';

const ApplicantSuitableVacancies = ({ suitableVacancies }) => {
    if (!suitableVacancies?.vacancies) {
        return null;
    }

    return (
        <Fragment>
            <ApplicantSuitableVacanciesHeader />
            <Column xs="4" s="8" m="12" l="16">
                <div className="recommended-vacancies">
                    {suitableVacancies.vacancies.map((vacancy) => (
                        <VacancySearchItem
                            key={vacancy.vacancyId}
                            vacancy={vacancy}
                            vacancySource={Source.ApplicantNegotiations}
                            hhtmFromLabel="suitable_vacancies"
                        />
                    ))}
                </div>
            </Column>
        </Fragment>
    );
};

ApplicantSuitableVacancies.propTypes = {
    suitableVacancies: PropTypes.shape({
        resultsFound: PropTypes.number,
        totalPages: PropTypes.number,
        itemsOnPage: PropTypes.number,
        vacancies: PropTypes.array,
    }),
};

export const ApplicantSuitableVacanciesRaw = ApplicantSuitableVacancies;
export const mapStateToProps = (state) => ({
    suitableVacancies: state.suitableVacancies,
});

export default connect(mapStateToProps)(ApplicantSuitableVacanciesRaw);
