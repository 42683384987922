import type { FC } from 'react';

import { NumberPages } from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';

import { useSelector } from 'src/hooks/useSelector';
import type { Paging } from 'src/models/paging.types';

import styles from './styles.less';

interface ApplicantNegotiationListPaginationProps {
    paging: Paging;
}

const ApplicantNegotiationListPagination: FC<ApplicantNegotiationListPaginationProps> = ({ paging }) => {
    const location = useSelector((state) => state.router.location);
    const queryParams = new URLSearchParams(location.search);
    const push = usePush();

    const { pages, firstPage, lastPage } = paging;

    const currentPageIndex = pages.find((page) => page.selected)?.page ?? 0;
    const firstPageIndex = firstPage?.page ?? pages[0]?.page ?? 0;
    const lastPageIndex = lastPage?.page ?? pages[pages.length - 1]?.page ?? 0;

    const handlePageNumberChange = (page: number): void => {
        queryParams.set('page', String(page - 1));
        push(`${location.pathname}?${queryParams.toString()}`);
    };

    return (
        <div className={styles.container}>
            <NumberPages
                current={currentPageIndex + 1}
                first={firstPageIndex + 1}
                last={lastPageIndex + 1}
                onChange={handlePageNumberChange}
            />
        </div>
    );
};

export default ApplicantNegotiationListPagination;
