import PropTypes from 'prop-types';

import { VSpacing, VSpacingContainer, Title } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';

import MagritteDefaultLayout from 'src/app/layouts/MagritteLayouts/DefaultLayout';
import PageLayout, { HeaderLayout } from 'src/app/layouts/PageLayout';
import JobSearchStatusBanner from 'src/components/Applicant/JobSearchStatus/JobSearchStatusBanner';
import AutoSearchMessengersTip from 'src/components/AutosearchMessengers/AutoSearchMessengersTip';
import ClickmeList from 'src/components/Clickme/List';
import ClickmeReferralLink from 'src/components/Clickme/ReferralLink';
import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import NegotiationsFilterByActive from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/FiltersByActive';
import NegotiationsList from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiations/List';
import EmptyList from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/EmptyList';
import MagritteNegotiationList from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List';
import NegotiationsFilter from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/NegotiationsFilter';
import ApplicantSuitableVacancies from 'src/pages/ApplicantNegotiations/components/ApplicantSuitableVacancies';
import Surveys from 'src/pages/ApplicantNegotiations/components/Surveys';

const TrlKeys = {
    title: 'applicant.negotiations.page.title',
};

const ApplicantNegotiations = ({ trls }) => {
    const isNegotiationsMagritteExp = useSelector((state) => state.isNegotiationsMagritteExp);
    const { topicList } = useSelector((state) => state.applicantNegotiations);

    if (isNegotiationsMagritteExp) {
        return (
            <PageLayout title={trls[TrlKeys.title]} layout={MagritteDefaultLayout}>
                <VSpacingContainer default={24} gteM={40}>
                    <Title size="large">{trls[TrlKeys.title]}</Title>
                    <Surveys />
                    <JobSearchStatusBanner />
                    <NegotiationsFilter />
                </VSpacingContainer>
                {topicList.length > 0 ? (
                    <>
                        <VSpacing default={24} gteM={32} />
                        <VSpacingContainer default={16} gteM={24}>
                            <MagritteNegotiationList />
                            <AutoSearchMessengersTip />
                        </VSpacingContainer>
                    </>
                ) : (
                    <EmptyList />
                )}
                <ApplicantSuitableVacancies />
                <ClickmeList
                    clickmeReferralLink={
                        <Column xs="4" s="8" m="12" l="16">
                            <ClickmeReferralLink />
                        </Column>
                    }
                />
                {topicList.length > 0 && <ContainerForMicroFrontend place="topicInterviewReviewWizard" />}
            </PageLayout>
        );
    }

    return (
        <PageLayout title={trls[ApplicantNegotiations.trls.title]} layout={HeaderLayout}>
            <Column xs="4" s="8" m="12" l="16">
                <Surveys />
                <JobSearchStatusBanner />
                <NegotiationsFilterByActive />
                <NegotiationsList />
            </Column>
            <AutoSearchMessengersTip />
            <ApplicantSuitableVacancies />
            <ClickmeList
                clickmeReferralLink={
                    <Column xs="4" s="8" m="12" l="16">
                        <ClickmeReferralLink />
                    </Column>
                }
            />
            <ContainerForMicroFrontend place="topicInterviewReviewWizard" />
        </PageLayout>
    );
};

ApplicantNegotiations.trls = {
    title: 'vacancyresponses.and.invitations.title',
};

ApplicantNegotiations.propTypes = {
    trls: PropTypes.object,
};

export default translation(ApplicantNegotiations);
