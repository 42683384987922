import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';
import { H2 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'src/components/translation';

const Header = ({ trls }) => (
    <Column xs="4" s="8" m="12" l="16">
        <H2>{trls[Header.trls.title]}</H2>
        <VSpacing base={4} />
    </Column>
);

Header.trls = {
    title: 'applicant.negotiations.suitableVacancies',
};

Header.propTypes = {
    trls: PropTypes.object,
};

export default translation(Header);
