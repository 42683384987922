import { FC } from 'react';
import classNames from 'classnames';

import { Card } from '@hh.ru/magritte-ui';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import { useSelector } from 'src/hooks/useSelector';
import { BannerPlace } from 'src/models/banners';

import styles from './responses.less';

const ListBanner: FC = () => {
    const bannerL = useSelector((state) => state.banners[BannerPlace.AFTER_4TH_TOPIC_L]);
    const bannerM = useSelector((state) => state.banners[BannerPlace.AFTER_4TH_TOPIC_M]);
    const bannerS = useSelector((state) => state.banners[BannerPlace.AFTER_4TH_TOPIC_S]);
    const bannerXS = useSelector((state) => state.banners[BannerPlace.AFTER_4TH_TOPIC_XS]);

    const isNegotiationsMagritteExp = useSelector((state) => state.isNegotiationsMagritteExp);

    if (!bannerL && !bannerM && !bannerS && !bannerXS) {
        return null;
    }

    const renderBanners = () => (
        <>
            {bannerL && bannerL.length > 0 && (
                <div className="banner-place-wrapper_show-on-l">
                    <AdsExternalBanner {...bannerL[0]} />
                </div>
            )}
            {bannerM && bannerM.length > 0 && (
                <div className="banner-place-wrapper_show-on-m">
                    <AdsExternalBanner {...bannerM[0]} />
                </div>
            )}
            {bannerS && bannerS.length > 0 && (
                <div className="banner-place-wrapper_show-on-s">
                    <AdsExternalBanner {...bannerS[0]} />
                </div>
            )}
            {bannerXS && bannerXS.length > 0 && (
                <div className="banner-place-wrapper_show-on-xs">
                    <AdsExternalBanner {...bannerXS[0]} />
                </div>
            )}
        </>
    );

    if (isNegotiationsMagritteExp) {
        return (
            <Card stretched padding={16} borderRadius={16} style="neutral">
                {renderBanners()}
            </Card>
        );
    }

    return (
        <tr className={classNames(styles.responsesTableRow, styles.responsesTableRowBanner)}>
            <td className={styles.responsesTableRowCell} colSpan={6}>
                <div className="banner-substrate">{renderBanners()}</div>
            </td>
        </tr>
    );
};

export default ListBanner;
