import { Tag } from '@hh.ru/magritte-ui';
import { PhoneArrowInOutlinedSize16, PhoneCrossOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import type { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { TopicCall } from 'src/models/topicsCallHistory';
import getCallDuration from 'src/utils/callTracking/utils';

const TrlKeys = {
    callResponse: {
        missed: 'applicant.negotiations.callResponse.missed',
        duration: 'applicant.negotiations.callResponse.duration',
    },
    min: 'min',
    sec: 'sec',
};

interface CallHistoryTagProps {
    topicCall: TopicCall;
}

const CallHistoryTag: TranslatedComponent<CallHistoryTagProps> = ({ trls, topicCall }) => {
    const callDuration = getCallDuration(topicCall, {
        min: trls[TrlKeys.min],
        sec: trls[TrlKeys.sec],
    });

    return (
        <Tag
            style={callDuration ? 'neutral' : 'negative'}
            icon={callDuration ? PhoneArrowInOutlinedSize16 : PhoneCrossOutlinedSize16}
        >
            {callDuration
                ? format(trls[TrlKeys.callResponse.duration], { '{0}': callDuration })
                : trls[TrlKeys.callResponse.missed]}
        </Tag>
    );
};

export default translation(CallHistoryTag);
