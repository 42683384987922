import { useState, useRef, useMemo, useCallback } from 'react';

import {
    HSpacingContainer,
    Button,
    Drop,
    useBreakpoint,
    ButtonStyle,
    VSpacingContainer,
    BottomSheet,
    NavigationBar,
    Action,
    VSpacing,
} from '@hh.ru/magritte-ui';
import {
    EllipsisHorizontalOutlinedSize16,
    TrashOutlinedSize16,
    TrashOutlinedSize24,
    CrossCircleOutlinedSize16,
    LoaderOutlinedSize24,
    BubbleRoundOutlinedSize24,
    CrossOutlinedSize24,
    DocumentOutlinedSize24,
    BriefcaseOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import type { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import MagritteInfotip from 'src/components/Infotip/MagritteInfotip';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { TopicAction, TopicActionName } from 'src/models/applicantNegotiationTopic.types';
import PendingAutoActionSurveyTag from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Card/PendingAutoActionSurveyTag';

import styles from './styles.less';

const TrlKeys = {
    actions: {
        [TopicActionName.Decline]: 'applicant.negotiations.actions.decline',
        [TopicActionName.Delete]: 'applicant.negotiations.actions.delete',
        [TopicActionName.Restore]: 'applicant.negotiations.actions.restore',
        [TopicActionName.Feedback]: 'applicant.negotiations.action.feedback',
    },

    feedbackTip: 'applicant.negotiations.feedback.infotip',
    buttons: {
        chat: 'applicant.negotiations.buttons.chat',
        stat: 'applicant.negotiations.buttons.stat',
        toVacancy: 'applicant.negotiations.buttons.to.vacancy',
        toEmployer: 'applicant.negotiations.buttons.to.employer',
    },
};

const ActionStyleMap: Record<TopicActionName, ButtonStyle> = {
    [TopicActionName.Decline]: 'neutral',
    [TopicActionName.Feedback]: 'neutral',
    [TopicActionName.Restore]: 'neutral',
    [TopicActionName.Delete]: 'negative',
};

interface ActionBarProps {
    actions: TopicAction[];
    topicId: number;
    chatId?: number;
    isEmployerBanned?: boolean;
    applicantVacancySummaryEnabled?: boolean;
    hasPendingAutoActionSurvey?: boolean;
    isArchived?: boolean;
    employerName?: string;
    vacancyName?: string;
    isBottomSheetVisible: boolean;
    shouldShowChatButtonOnCard: boolean;
    employerId: number;
    vacancyId: number;
    areActionsDisabled?: boolean;
    onBottomSheetClose: VoidFunction;
    onChatButtonClick: VoidFunction;
    onStatusChange: (action: TopicAction, id: number) => void;
    isFeedbackTipVisible: boolean;
}

const ActionBar: TranslatedComponent<ActionBarProps> = ({
    trls,
    actions,
    chatId,
    topicId,
    isEmployerBanned,
    applicantVacancySummaryEnabled,
    hasPendingAutoActionSurvey,
    isArchived,
    vacancyName,
    employerName,
    isBottomSheetVisible,
    employerId,
    vacancyId,
    shouldShowChatButtonOnCard,
    areActionsDisabled,
    onBottomSheetClose,
    onChatButtonClick,
    onStatusChange,
    isFeedbackTipVisible,
}) => {
    const feedbackAction = useMemo(() => actions.find((action) => action.name === TopicActionName.Feedback), [actions]);
    const restoreAction = useMemo(() => actions.find((action) => action.name === TopicActionName.Restore), [actions]);
    const deleteAction = useMemo(() => actions.find((action) => action.name === TopicActionName.Delete), [actions]);
    const declineAction = useMemo(() => actions.find((action) => action.name === TopicActionName.Decline), [actions]);
    const changeStatusActions = useMemo(
        () =>
            actions.filter((action) =>
                (isArchived ? [TopicActionName.Decline] : [TopicActionName.Delete, TopicActionName.Decline]).includes(
                    action.name
                )
            ),
        [isArchived, actions]
    );

    const hasRestoreAction = !!restoreAction;
    const hasVacancyResponsesSummaryService = useSelector((state) => state.hasVacancyResponsesSummaryService);

    const [isMenuForMVisible, setMenuForMVisible] = useState(false);
    const activatorRef = useRef(null);

    const { isM, isGtM, isMobile } = useBreakpoint();

    const handleActionButtonClick = useCallback(
        (action: TopicAction) => {
            onStatusChange(action, topicId);
        },
        [topicId, onStatusChange]
    );

    const leftPartButtonsList = useMemo(() => {
        const list = [];

        if (isArchived && deleteAction) {
            list.push(
                <Button
                    key={deleteAction.name}
                    disabled={areActionsDisabled}
                    mode="secondary"
                    style="accent"
                    size="small"
                    stretched={isMobile}
                    onClick={() => {
                        handleActionButtonClick(deleteAction);
                    }}
                    data-qa={`negotiations-${deleteAction.name}`}
                >
                    {trls[TrlKeys.actions[deleteAction.name]]}
                </Button>
            );
        }
        if (!!feedbackAction && !isMobile) {
            list.push(
                <MagritteInfotip
                    key="infotip"
                    name="applicant_feedback_about_vacancy_infotip"
                    placement="top-center"
                    closeByClickOutside
                    visible={isFeedbackTipVisible}
                    showBannerOnMobile={false}
                    markAsViewedEvent={MarkAsViewedEvent.OnHide}
                    render={({ activatorRef }) => (
                        <span ref={activatorRef}>
                            <ContainerForMicroFrontend
                                place={`topicInterviewReview${topicId}`}
                                preloadContent={
                                    <Button
                                        mode="secondary"
                                        style="accent"
                                        size="small"
                                        loading
                                        disabled
                                        hideLabel
                                        aria-label="loading"
                                        icon={<LoaderOutlinedSize24 />}
                                    />
                                }
                            />
                        </span>
                    )}
                    useDefaultHost
                    showClose
                >
                    {trls[TrlKeys.feedbackTip]}
                </MagritteInfotip>
            );
        }

        if (restoreAction) {
            list.push(
                <Button
                    key={restoreAction.name}
                    disabled={areActionsDisabled}
                    mode="secondary"
                    style="accent"
                    size="small"
                    stretched={isMobile}
                    onClick={() => {
                        handleActionButtonClick(restoreAction);
                    }}
                    data-qa={`negotiations-${restoreAction.name}`}
                >
                    {trls[TrlKeys.actions[restoreAction.name]]}
                </Button>
            );
        }

        if (!!chatId && shouldShowChatButtonOnCard) {
            list.push(
                <Button
                    key="chatik"
                    disabled={areActionsDisabled}
                    mode="secondary"
                    style="accent"
                    size="small"
                    stretched={isMobile}
                    onClick={onChatButtonClick}
                >
                    {trls[TrlKeys.buttons.chat]}
                </Button>
            );
        }

        if (!isEmployerBanned && applicantVacancySummaryEnabled) {
            list.push(
                <Button
                    key="stat"
                    disabled={areActionsDisabled}
                    Element={Link}
                    mode="secondary"
                    style="accent"
                    stretched={isMobile}
                    to={
                        hasVacancyResponsesSummaryService
                            ? `/applicant-services/vacancy-summary/${topicId}`
                            : `/article/vacancy_summary?topicId=${topicId}`
                    }
                    size="small"
                >
                    {trls[TrlKeys.buttons.stat]}
                </Button>
            );
        }

        if (isMobile && declineAction) {
            list.push(
                <Button
                    key={declineAction.name}
                    disabled={areActionsDisabled}
                    mode="secondary"
                    style="accent"
                    size="small"
                    stretched={isMobile}
                    onClick={() => {
                        handleActionButtonClick(declineAction);
                    }}
                    data-qa={`negotiations-${declineAction.name}`}
                >
                    {trls[TrlKeys.actions[declineAction.name]]}
                </Button>
            );
        }

        return list;
    }, [
        isArchived,
        deleteAction,
        feedbackAction,
        isMobile,
        restoreAction,
        chatId,
        shouldShowChatButtonOnCard,
        isEmployerBanned,
        applicantVacancySummaryEnabled,
        declineAction,
        areActionsDisabled,
        trls,
        handleActionButtonClick,
        isFeedbackTipVisible,
        topicId,
        onChatButtonClick,
        hasVacancyResponsesSummaryService,
    ]);

    const renderRightPartButtons = () => (
        <HSpacingContainer default={12}>
            {isGtM && (
                <>
                    {hasPendingAutoActionSurvey && (
                        <PendingAutoActionSurveyTag topicId={topicId} onClick={onChatButtonClick} />
                    )}
                    {changeStatusActions.map((action: TopicAction) => (
                        <Button
                            disabled={areActionsDisabled}
                            key={action.name}
                            mode="tertiary"
                            size="small"
                            style="accent"
                            onClick={() => {
                                handleActionButtonClick(action);
                            }}
                            data-qa={`negotiations-${action.name}`}
                        >
                            {trls[TrlKeys.actions[action.name]]}
                        </Button>
                    ))}
                </>
            )}

            {isM && (
                <>
                    <Button
                        disabled={areActionsDisabled}
                        icon={<EllipsisHorizontalOutlinedSize16 />}
                        style="accent"
                        mode="tertiary"
                        size="small"
                        ref={activatorRef}
                        aria-label=""
                        hideLabel
                        onClick={() => setMenuForMVisible(!isMenuForMVisible)}
                    />
                    <Drop
                        visible={isMenuForMVisible}
                        onClose={() => setMenuForMVisible(!isMenuForMVisible)}
                        placement="bottom-right"
                        space={300}
                        activatorRef={activatorRef}
                        closeByClickOutside
                    >
                        {hasPendingAutoActionSurvey && (
                            <PendingAutoActionSurveyTag topicId={topicId} onClick={onChatButtonClick} />
                        )}
                        {changeStatusActions.map((action) => (
                            <Button
                                disabled={areActionsDisabled}
                                key={action.name}
                                mode="tertiary"
                                style={ActionStyleMap[action.name] ?? 'neutral'}
                                size="small"
                                onClick={() => {
                                    handleActionButtonClick(action);
                                }}
                                data-qa={`negotiations-${action.name}`}
                                icon={
                                    action.name === TopicActionName.Decline ? (
                                        <CrossCircleOutlinedSize16 />
                                    ) : (
                                        <TrashOutlinedSize16 />
                                    )
                                }
                                stretched
                            >
                                {trls[TrlKeys.actions[action.name]]}
                            </Button>
                        ))}
                    </Drop>
                </>
            )}
        </HSpacingContainer>
    );

    const renderBottomSheet = () => (
        <BottomSheet
            visible={isBottomSheetVisible}
            header={
                <NavigationBar
                    title={vacancyName ?? ''}
                    subtitle={employerName ?? ''}
                    right={<Action icon={CrossOutlinedSize24} onClick={onBottomSheetClose} />}
                />
            }
            onClose={onBottomSheetClose}
        >
            <>
                {!!chatId && (
                    <Button
                        disabled={areActionsDisabled}
                        mode="tertiary"
                        style="neutral"
                        size="large"
                        stretched
                        onClick={onChatButtonClick}
                        icon={<BubbleRoundOutlinedSize24 />}
                    >
                        {trls[TrlKeys.buttons.chat]}
                    </Button>
                )}
                <Button
                    Element={Link}
                    mode="tertiary"
                    style="neutral"
                    size="large"
                    stretched
                    to={`vacancy/${vacancyId}`}
                    icon={<DocumentOutlinedSize24 />}
                >
                    {trls[TrlKeys.buttons.toVacancy]}
                </Button>
                <Button
                    Element={Link}
                    mode="tertiary"
                    style="neutral"
                    size="large"
                    stretched
                    to={`employer/${employerId}`}
                    icon={<BriefcaseOutlinedSize24 />}
                >
                    {trls[TrlKeys.buttons.toEmployer]}
                </Button>
                <div onClick={onBottomSheetClose}>
                    <ContainerForMicroFrontend place={`topicInterviewReview${topicId}`} />
                </div>
                {deleteAction && (
                    <Button
                        disabled={areActionsDisabled}
                        key={deleteAction.name}
                        mode="tertiary"
                        size="large"
                        style="negative"
                        onClick={() => {
                            handleActionButtonClick(deleteAction);
                        }}
                        data-qa={`negotiations-${deleteAction.name}`}
                        icon={<TrashOutlinedSize24 />}
                        stretched
                    >
                        {trls[TrlKeys.actions[deleteAction.name]]}
                    </Button>
                )}
            </>
        </BottomSheet>
    );

    if (isMobile && !leftPartButtonsList.length && !hasPendingAutoActionSurvey) {
        return renderBottomSheet();
    }
    if (!isMobile && !leftPartButtonsList.length && !changeStatusActions.length && !hasPendingAutoActionSurvey) {
        return null;
    }

    return (
        <>
            <VSpacing default={24} />
            <div className={styles.container}>
                {isMobile && (
                    <div className={styles.buttonsContainer}>
                        <VSpacingContainer default={12}>
                            {leftPartButtonsList}
                            {hasPendingAutoActionSurvey && (
                                <PendingAutoActionSurveyTag topicId={topicId} onClick={onChatButtonClick} />
                            )}
                        </VSpacingContainer>
                    </div>
                )}
                {!isMobile && <HSpacingContainer default={12}>{leftPartButtonsList}</HSpacingContainer>}
                {!hasRestoreAction && !isArchived && renderRightPartButtons()}
                {isMobile && renderBottomSheet()}
            </div>
        </>
    );
};

export default translation(ActionBar);
