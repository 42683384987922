import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Banner, Button as MagritteButton, Text as MagritteText } from '@hh.ru/magritte-ui';
import Button from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import Link from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Strings from 'Utils/Strings';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';

import AutosearchMessengersModal from 'src/components/AutosearchMessengers/AutosearchMessengersModal';

const TrlKeys = {
    tip: 'autosearch.tip',
    text: 'autosearch.tip.text',
    button: 'autosearch.tip.button',
};

const analyticsData = { category: 'applicant', action: 'chatbot-messenger-setup', label: 'negotiations' };

const AutoSearchMessengersTip: TranslatedComponent = ({ trls }) => {
    const chatBot = useSelector((store) => store.chatBot);
    const isNegotiationsMagritteExp = useSelector((state) => state.isNegotiationsMagritteExp);
    const [modalIsVisible, toggleModal] = useToggleState(false);
    const handleButtonClick = useCallback(() => {
        toggleModal();
        Analytics.sendEvent(analyticsData.category, analyticsData.action, analyticsData.label);
    }, [toggleModal]);

    if (!chatBot) {
        return null;
    }

    if (isNegotiationsMagritteExp) {
        return (
            <>
                <Banner
                    stretched
                    showClose={false}
                    style="neutral"
                    content={
                        <MagritteText typography="paragraph-2-regular">
                            {Strings.capitalize(trls[TrlKeys.text])}
                        </MagritteText>
                    }
                    buttonBase={
                        <MagritteButton style="accent" mode="primary" size="small" onClick={handleButtonClick}>
                            {trls[TrlKeys.button]}
                        </MagritteButton>
                    }
                />
                <AutosearchMessengersModal isVisible={modalIsVisible} handleCloseModal={toggleModal} />
            </>
        );
    }

    return (
        <div className="autosearch-messengers-tip-wrap">
            <div className="autosearch-messengers-tip">
                <span className="autosearch-messengers-tip-text-wrap">
                    <Gap right Element="span">
                        <Text Element="span" strong>
                            {trls[TrlKeys.tip]}
                        </Text>{' '}
                        {trls[TrlKeys.text]}
                    </Gap>
                </span>
                <Link Element={Button} onClick={handleButtonClick}>
                    {trls[TrlKeys.button]}
                </Link>
            </div>
            <AutosearchMessengersModal isVisible={modalIsVisible} handleCloseModal={toggleModal} />
        </div>
    );
};

export default translation(AutoSearchMessengersTip);
