import { Button, Card, Checkbox, Cell, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    delete: 'applicant.negotiations.actions.delete',
};

interface ApplicantNegotiationListBatchActionProps {
    isAllSelected?: boolean;
    isSomeSelected?: boolean;
    onSelectAll?: VoidFunction;
    onDeleteSelected?: VoidFunction;
}

const ApplicantNegotiationListBatchAction: TranslatedComponent<ApplicantNegotiationListBatchActionProps> = ({
    trls,
    isAllSelected,
    isSomeSelected,
    onSelectAll,
    onDeleteSelected,
}) => {
    const { isMobile } = useBreakpoint();

    const hasSelected = isAllSelected || isSomeSelected;

    const handleChange = (): void => {
        if (onSelectAll) {
            onSelectAll();
        }
    };

    const handleClick = (): void => {
        if (onDeleteSelected) {
            onDeleteSelected();
        }
    };

    return (
        <Card showBorder={!isMobile} padding={isMobile ? 0 : 12} borderRadius={isMobile ? 0 : 24}>
            <Cell
                left={
                    <Card style="neutral" padding={8} borderRadius={12}>
                        <Checkbox checked={isAllSelected} indeterminate={isSomeSelected} onChange={handleChange} />
                    </Card>
                }
            >
                <Button size="small" mode="secondary" disabled={!hasSelected} onClick={handleClick}>
                    {trls[TrlKeys.delete]}
                </Button>
            </Cell>
        </Card>
    );
};

export default translation(ApplicantNegotiationListBatchAction);
