import { Tag } from '@hh.ru/magritte-ui';
import type { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import ConversionDate from 'src/components/Conversion/DateOnly';
import translation from 'src/components/translation';
import {
    type ManagerActivityData,
    ManagerInactiveTrlCode,
} from 'src/models/applicantNegotiations/applicantEmployerManagersActivity';

const TrlKeys = {
    lastActivity: {
        [ManagerInactiveTrlCode.Online]: 'applicant.negotiations.activity.online',
        [ManagerInactiveTrlCode.Today]: 'applicant.negotiations.employerStatistics.lastActivity.today',
        [ManagerInactiveTrlCode.Yesterday]: 'applicant.negotiations.employerStatistics.lastActivity.yesterday',
        [ManagerInactiveTrlCode.Week]: 'applicant.negotiations.employerStatistics.lastActivity.week',
        [ManagerInactiveTrlCode.Month]: 'applicant.negotiations.employerStatistics.lastActivity.month',
        [ManagerInactiveTrlCode.WeekExact]: 'applicant.negotiations.employerStatistics.lastActivity.days',
    },
};

interface EmployerLastActivityTagProps {
    lastActivity: ManagerActivityData;
}

const EmployerLastActivityTag: TranslatedComponent<EmployerLastActivityTagProps> = ({ trls, lastActivity }) => {
    const { trl_code: trlCode, inactive_days: inactiveDays } = lastActivity;

    if (lastActivity.trl_code === ManagerInactiveTrlCode.Online) {
        return null;
    }

    if (lastActivity.trl_code === ManagerInactiveTrlCode.WeekExact) {
        return (
            <Tag>
                {formatToReactComponent(trls[TrlKeys.lastActivity[trlCode]], {
                    '{0}': <ConversionDate value={inactiveDays} />,
                })}
            </Tag>
        );
    }
    return <Tag>{trls[TrlKeys.lastActivity[trlCode]]}</Tag>;
};

export default translation(EmployerLastActivityTag);
