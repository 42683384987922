import { VSpacing, Button, Text, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import StaticImg from 'src/components/StaticImg';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import {
    ApplicantNegotiationStatus,
    ApplicantNegotiationFiltersStates,
} from 'src/models/applicantNegotiations/applicantNegotiationsActionsData';

import styles from './styles.less';

const TrlKeys = {
    title: 'applicant.negotiations.empty.title',
    text: 'applicant.negotiations.empty.text',
    button: 'applicant.negotiations.empty.button',
};

const EmptyList: TranslatedComponent = ({ trls }) => {
    const { isXS } = useBreakpoint();
    const { query } = useSelector((state) => state.router.location);
    const isButtonHidden =
        query.filter === ApplicantNegotiationFiltersStates.Archived ||
        query.filter === ApplicantNegotiationFiltersStates.Deleted ||
        query.state === ApplicantNegotiationStatus.Discard;

    return (
        <>
            <VSpacing default={56} />
            <div className={styles.container}>
                <StaticImg path="/i/hh/applicant/negotiations/emptyList.svg" width={isXS ? '293' : '378'} />
                <VSpacing default={32} />
                <Text typography="title-5-semibold" style="primary" data-qa="negotiations-list-empty-title">
                    {trls[TrlKeys.title]}
                </Text>
                {!isButtonHidden && (
                    <>
                        <VSpacing default={8} />
                        <Text typography="paragraph-2-regular" style="secondary" data-qa="negotiations-list-empty-text">
                            {trls[TrlKeys.text]}
                        </Text>
                        <VSpacing default={32} />
                        <Button
                            Element={Link}
                            to="/search/vacancy"
                            stretched={isXS}
                            size="small"
                            style="accent"
                            mode="primary"
                        >
                            {trls[TrlKeys.button]}
                        </Button>
                    </>
                )}
            </div>
            <VSpacing default={56} />
        </>
    );
};

export default translation(EmptyList);
