import classNames from 'classnames';

import Button from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import { TrashScaleSmall, IconColor } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './responses.less';

interface BatchActionProps {
    batchRemove: () => void;
    show: boolean;
}

const TrlKeys = {
    actionDelete: 'applicant.negotiations.action.delete.bulk',
};

const BatchAction: TranslatedComponent<BatchActionProps> = ({ show, batchRemove, trls }) => {
    if (!show) {
        return null;
    }

    return (
        <div className={classNames(styles.responsesTopControl, styles.responsesTopControlDiscard)}>
            <span className={classNames(styles.responsesTopControl, styles.responsesTopControlButtonHideOnXs)}>
                <FormSpacer>
                    <Button onClick={batchRemove} data-qa="responses-action-batch-remove">
                        {trls[TrlKeys.actionDelete]}
                    </Button>
                </FormSpacer>
            </span>
            <span className={styles.responsesTopControlButtonShowOnXs}>
                <Button
                    data-qa="responses-action-batch-remove"
                    onClick={batchRemove}
                    icon={<TrashScaleSmall initial={IconColor.Gray60} />}
                />
            </span>
        </div>
    );
};

export default translation(BatchAction);
