import { useDispatch } from 'react-redux';

import { Banner, Button, Title, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { Link } from '@hh.ru/redux-spa-middleware';
import { CrossScaleSmallEnclosedFalse, IconLink, IconColor } from 'bloko/blocks/icon';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

declare global {
    interface FetcherPostApi {
        '/surveys/finish': {
            queryParams: void;
            body: {
                sid: string;
            };
            response: void;
        };
    }
}

const surveysAction = makeSetStoreField('surveys');

const TrlKeys = {
    take: 'survey.take',
    title: 'survey.title',
    start: 'survey.button.start',
};

const Surveys: TranslatedComponent = ({ trls }) => {
    const isZarplata = useIsZarplataPlatform();
    const { addNotification } = useNotification();
    const dispatch = useDispatch();
    const surveys = useSelector((state) => state.surveys);
    const isNegotiationsMagritteExp = useSelector((state) => state.isNegotiationsMagritteExp);

    const removeSurvey = (id: string) => {
        return fetcher
            .postFormData('/surveys/finish', {
                sid: id,
            })
            .then(() => dispatch(surveysAction(surveys.filter((item) => item.id !== id))))
            .catch((error) => defaultRequestErrorHandler(error, addNotification));
    };

    if (isZarplata) {
        return null;
    }
    if (surveys.length === 0) {
        return null;
    }

    if (isNegotiationsMagritteExp) {
        return (
            <>
                <VSpacingContainer default={16} gteM={24}>
                    {surveys.map(({ url, id, text }) => (
                        <Banner
                            key={id}
                            stretched
                            onClose={() => removeSurvey(id)}
                            title={
                                <Title Element="h5" size="small">
                                    {trls[TrlKeys.title]}
                                </Title>
                            }
                            style="neutral"
                            content={<Text typography="paragraph-2-regular">{text}</Text>}
                            buttonBase={
                                <Button style="accent" mode="primary" size="small" Element={Link} to={url}>
                                    {trls[TrlKeys.start]}
                                </Button>
                            }
                        />
                    ))}
                </VSpacingContainer>
            </>
        );
    }
    return (
        <>
            {surveys.map(({ url, id, text }) => (
                <div className="announcement" key={id}>
                    <BlokoLink disableVisited Element={Link} to={url} target="_blank">
                        {trls[TrlKeys.take]}
                        {` "${text}"`}
                    </BlokoLink>
                    <IconLink onClick={() => removeSurvey(id)}>
                        <CrossScaleSmallEnclosedFalse initial={IconColor.Gray50} />
                    </IconLink>
                </div>
            ))}
        </>
    );
};

export default translation(Surveys);
