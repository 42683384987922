export enum TopicActionName {
    Decline = 'decline',
    Delete = 'delete',
    Restore = 'restore',
    Feedback = 'feedback',
}

export interface TopicAction {
    name: TopicActionName;
    method: string;
    href: string;
    substate: string;
}

type TopicState = 'RESPONSE' | 'INVITATION' | 'DISCARD' | 'DISCARD_AFTER_INTERVIEW';

export interface Topic {
    id: number;
    resumeId: number;
    vacancyId: number;
    employerId: number;
    lastModified?: number;
    lastModifiedMillis: number;
    creationTime: number;
    employerCreationTime: number;
    responseLetter: string;
    hasNewMessages: boolean;
    chatId: number;
    actions?: TopicAction[];
    cantBeOpenedWithCurrentResume?: boolean;
    applicantVacancySummaryEnabled: boolean;
    archived: boolean;
    lastSubState: string;
    lastState: TopicState;
    viewedByOpponent: boolean;
    employerViolatesRules: boolean;
    hasPendingAutoActionSurvey?: boolean;
}
