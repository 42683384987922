import { FC, PropsWithChildren, useState } from 'react';
import classnames from 'classnames';

import { useChatik } from '@hh.ru/chatik-integration';
import {
    Card,
    Checkbox,
    HSpacing,
    Tag,
    VSpacingContainer,
    Action,
    useBreakpoint,
    Text,
    Avatar,
} from '@hh.ru/magritte-ui';
import { CircleFilledSize16, EllipsisHorizontalOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link, usePush } from '@hh.ru/redux-spa-middleware';
import type { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import HumanDate, { ShowYear } from 'src/components/HumanDate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import type { Topic, TopicAction } from 'src/models/applicantNegotiationTopic.types';
import {
    ManagerActivityData,
    ManagerInactiveTrlCode,
} from 'src/models/applicantNegotiations/applicantEmployerManagersActivity';
import {
    TopicStatus,
    getTopicStatus,
} from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/utils';

import ActionBar from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Card/ActionBar';
import CallHistoryTag from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Card/CallHistoryTag';
import EmployerLastActivityTag from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Card/EmployerLastActivityTag';
import EmployerResponseRateTag from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Card/EmployerResponseRateTag';
import { TagStyleMap } from 'src/pages/ApplicantNegotiations/components/ApplicantNegotiationsMagritte/List/Card/utils';

import styles from './styles.less';

const TrlKeys = {
    status: {
        [TopicStatus.Accepted]: 'applicant.negotiations.topicStatus.accepted',
        [TopicStatus.Rejected]: 'applicant.negotiations.topicStatus.rejected',
        [TopicStatus.Viewed]: 'applicant.negotiations.topicStatus.viewed',
        [TopicStatus.NotViewed]: 'applicant.negotiations.topicStatus.notViewed',
        [TopicStatus.Archived]: 'applicant.negotiations.topicStatus.archived',
        [TopicStatus.Deleted]: 'applicant.negotiations.topicStatus.deleted',
    },
    employerViolatesRules: 'applicant.negotiations.employerViolatesRules',
};

interface ApplicantNegotiationCardProps {
    topic: Topic;
    employerLastActivity?: ManagerActivityData;
    vacancyName: string;
    employerName: string;
    employerLogo?: string;
    isSelected?: boolean;
    areActionsDisabled?: boolean;
    onSelect: (id: number) => void;
    onStatusChange: (action: TopicAction, id: number) => void;
    isFeedbackTipVisible: boolean;
}

const ApplicantNegotiationCard: TranslatedComponent<ApplicantNegotiationCardProps> = ({
    trls,
    topic,
    vacancyName,
    employerName,
    employerLogo = '',
    employerLastActivity,
    isSelected,
    areActionsDisabled,
    onSelect,
    onStatusChange,
    isFeedbackTipVisible,
}) => {
    const {
        id,
        lastModifiedMillis,
        lastState,
        lastSubState,
        viewedByOpponent,
        archived: isArchived,
        hasNewMessages,
        employerId,
        employerViolatesRules: isEmployerBanned,
        chatId,
        applicantVacancySummaryEnabled,
        actions = [],
        hasPendingAutoActionSurvey,
        vacancyId,
    } = topic;
    const [isBottomSheetVisible, setBottomSheetVisible] = useState(false);
    const [isStatusChanged, setIsStatusChanged] = useState(hasNewMessages);
    const { isMobile } = useBreakpoint();
    const { openChatik } = useChatik();
    const push = usePush();

    const isNegotiationsMagritteExpB = useSelector((state) => state.isNegotiationsMagritteExpB);
    const isNegotiationsMagritteExpC = useSelector((state) => state.isNegotiationsMagritteExpC);

    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource);

    const topicCall = useSelector((state) => state.topicsCallHistory[topic.id]);

    const politenessIndexes = useSelector((state) => state.applicantEmployerPoliteness.employerPolitenessIndexes);
    const employerResponseRate = politenessIndexes[employerId]?.readTopicPercent;

    const status = getTopicStatus(lastState, lastSubState, viewedByOpponent, isArchived);
    const isHiddenByUser = lastSubState === 'HIDE';
    // tempexp_31711
    // В анонимной вакансии employerId === "", но в типе топика это не учтено.
    // Пока оставил здесь коммент, т.к. менять глобально тип топика в рамках этой задачи ведет к большой переделке в проекте
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isAnonymousEmployer = employerId === '';
    const isCheckboxVisible = !(status === TopicStatus.Deleted || status === TopicStatus.Archived);

    const handleCheckboxChange = (): void => {
        onSelect(id);
    };

    const handleChatButtonClick = (): void => {
        setIsStatusChanged(false);

        if (chatId) {
            openChatik({
                chatId,
                view: 'widget',
                hhtmFromLabel: hhtmSource,
            });
        }
    };

    const handleCardClick = (): void => {
        if (isNegotiationsMagritteExpB && !isNegotiationsMagritteExpC) {
            handleChatButtonClick();
            return;
        }
        if (isNegotiationsMagritteExpC) {
            push(`/vacancy/${vacancyId}`);
        }
    };

    const VacancyNameWrapper: FC<PropsWithChildren> = ({ children }) => {
        return isMobile ? <>{children}</> : <Link to={`/vacancy/${vacancyId}`}>{children}</Link>;
    };

    const EmployerNameWrapper: FC<PropsWithChildren> = ({ children }) => {
        return isMobile || isAnonymousEmployer ? (
            <>{children}</>
        ) : (
            <Link to={`/employer/${employerId}`}>{children}</Link>
        );
    };

    const AvatarWrapper: FC<PropsWithChildren> = ({ children }) => {
        return isAnonymousEmployer ? <>{children}</> : <Link to={`/employer/${employerId}`}>{children}</Link>;
    };

    return (
        <Card
            showBorder
            stretched
            borderRadius={24}
            padding={isMobile ? 12 : 24}
            actionCard={isNegotiationsMagritteExpB && !isNegotiationsMagritteExpC}
            onClick={handleCardClick}
        >
            <div className={styles.container}>
                <VSpacingContainer default={16} gteM={20}>
                    <header className={styles.header}>
                        <div className={styles.toolbar}>
                            {isCheckboxVisible && (
                                <>
                                    <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
                                    <HSpacing default={12} />
                                </>
                            )}
                            <Tag
                                icon={isStatusChanged && status !== TopicStatus.Archived ? CircleFilledSize16 : void 0}
                                style={TagStyleMap[status]}
                                data-qa={classnames({
                                    'negotiations-item-invite': status === TopicStatus.Accepted,
                                    'negotiations-item-discard': status === TopicStatus.Rejected,
                                    'negotiations-item-not-viewed': status === TopicStatus.NotViewed,
                                    'negotiations-item-viewed': status === TopicStatus.Viewed,
                                })}
                            >
                                {trls[isEmployerBanned ? TrlKeys.employerViolatesRules : TrlKeys.status[status]]}
                            </Tag>
                        </div>
                        {isMobile && (
                            <Action
                                icon={EllipsisHorizontalOutlinedSize24}
                                mode="secondary"
                                style="neutral"
                                showBackground={false}
                                disablePadding={false}
                                onClick={() => {
                                    setBottomSheetVisible(true);
                                }}
                            />
                        )}
                    </header>
                    <section
                        className={classnames(styles.content, isMobile ? styles.contentMobile : styles.contentDesktop)}
                    >
                        <VSpacingContainer default={4}>
                            <VacancyNameWrapper>
                                <Text typography="subtitle-1-semibold" maxLines={2}>
                                    <span
                                        className={classnames({ [styles.textDisabled]: isHiddenByUser || isArchived })}
                                    >
                                        {vacancyName}
                                    </span>
                                </Text>
                            </VacancyNameWrapper>
                            <EmployerNameWrapper>
                                <Text
                                    typography="label-3-regular"
                                    data-qa="negotiations-item-company"
                                    className={classnames({ [styles.textDisabled]: isHiddenByUser || isArchived })}
                                >
                                    <span
                                        className={classnames({ [styles.textDisabled]: isHiddenByUser || isArchived })}
                                    >
                                        {employerName}
                                    </span>
                                </Text>
                            </EmployerNameWrapper>
                            <Text typography="label-3-regular" style="secondary" data-qa="negotiations-item-date">
                                <HumanDate date={lastModifiedMillis} showYear={ShowYear.NotCurrent} textify />
                            </Text>
                        </VSpacingContainer>
                        <AvatarWrapper>
                            <Avatar
                                mode="image"
                                image={employerLogo}
                                fallbackMode="placeholder"
                                placeholder="city"
                                shapeCircle
                                size={isMobile ? 40 : 48}
                                online={employerLastActivity?.trl_code === ManagerInactiveTrlCode.Online}
                                aria-label="Employer Logo"
                            />
                        </AvatarWrapper>
                    </section>
                    {(!!employerLastActivity || !!employerResponseRate || !!topicCall) && (
                        <section className={styles.tags}>
                            {!!employerLastActivity && <EmployerLastActivityTag lastActivity={employerLastActivity} />}
                            {!!employerResponseRate && <EmployerResponseRateTag responseRate={employerResponseRate} />}
                            {!!topicCall && <CallHistoryTag topicCall={topicCall} />}
                        </section>
                    )}
                </VSpacingContainer>
            </div>
            <ActionBar
                topicId={id}
                vacancyId={vacancyId}
                vacancyName={vacancyName}
                employerId={employerId}
                employerName={employerName}
                chatId={chatId}
                actions={actions}
                areActionsDisabled={areActionsDisabled}
                isArchived={isArchived}
                isEmployerBanned={isEmployerBanned}
                isBottomSheetVisible={isBottomSheetVisible}
                shouldShowChatButtonOnCard={isNegotiationsMagritteExpC}
                hasPendingAutoActionSurvey={hasPendingAutoActionSurvey}
                applicantVacancySummaryEnabled={applicantVacancySummaryEnabled}
                onBottomSheetClose={() => setBottomSheetVisible(false)}
                onChatButtonClick={handleChatButtonClick}
                onStatusChange={onStatusChange}
                isFeedbackTipVisible={isFeedbackTipVisible}
            />
        </Card>
    );
};

export default translation(ApplicantNegotiationCard);
